
















































import { Vue, Component } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import FacebookService from "@/services/FacebookService";
import ProfileService from "@/services/ProfileService";
import CompanyService from "@/services/CompanyService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import RouteUtil from "@/utilities/RouteUtil";
import ScreenUtil from "@/utilities/ScreenUtil";
import EventManager from "@/utilities/EventManager";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const MobileProfileInfoView = () => import("./MobileProfileInfoView.vue");
const MobileProfileChangePasswordView = () => import("./MobileProfileChangePasswordView.vue");
const MobileProfileAddressView = () => import("./MobileProfileAddressView.vue");
const MobileProfileGenerateTokenView = () => import("./MobileProfileGenerateTokenView.vue");

class MobileProfileProperties {
    user: any = {};
    events = new EventManager();
}

export { MobileProfileProperties };

@Component({
	metaInfo: {
		link: [{ rel: "stylesheet", href: "/profile.css" }]
	},
    components: { AlertDialogView },
    data: () => ({
        title: "",
        drawer: false,
        visibleLinkFacebook: false,
        visibleGenerateToken: false,
        visibleChangePassword: false
    }),
    computed: {
        screenMedium: function() {
            return this.$vuetify.breakpoint.mdAndDown;
        }
    }
})
export default class MobileProfileView extends Vue {
    private properties = new MobileProfileProperties();
    private alertDialog = new AlertDialogProperties();
    private component: any = null;
    private facebookService = new FacebookService();
    private profileService = new ProfileService();
    private companyService = new CompanyService();

    public async created() {
        if (!(this as any).screenMedium) {
            await this.$router.replace("/profile" + this.$route.hash);
        } else {
            this.$router.beforeEach((to, from, next) => {
                this.hashChanged(to.hash);
                next();
            });

            if (!this.checkHash(this.$route.hash)) {
                await this.$router.replace("/m/profile#main");
            } else {
                this.hashChanged(this.$route.hash);
            }
            await this.loadCompany();
            await this.load();
            this.properties.events.subscribe('reload', this.load);
            window.addEventListener("resize", this.windowResized);
            window.hamburger = this.hamburger;
        }
    }

    public destroyed() {
        this.properties.events.remove('reload', this.load);
        window.removeEventListener("resize", this.windowResized);
        window.hamburger = null;
    }

    public async windowResized() {
        if (!ScreenUtil.screenMedium(this.$vuetify.breakpoint)) {
            window.removeEventListener("resize", this.windowResized);
            await this.$router.replace("/profile" + this.$route.hash);
        }
    }

    public checkHash(hash: string) {
        return (
            hash === "#main" ||
            hash === "#address" ||
            hash === "#address-new" ||
            hash === "#change-password" ||
            hash === "#generate-token"
        );
    }

    public hashChanged(hash: string) {
        if (hash === "#main") {
            this.component = MobileProfileInfoView;
        } else if (hash === "#change-password") {
            this.component = MobileProfileChangePasswordView;
        } else if (hash === "#address" || hash === "#address-new") {
            this.component = MobileProfileAddressView;
        } else if (hash === "#generate-token") {
            this.component = MobileProfileGenerateTokenView;
        }
    }

    public async load() {
        try {
            const r = await this.profileService.get();
            this.$data.visibleLinkFacebook = (r.data.facebookAccountId ?? 0) === 0;
            this.$data.visibleGenerateToken = (r.data.facebookAccountId ?? 0) !== 0;
            this.$data.visibleChangePassword = r.data.passwordEnabled ?? false;
            this.properties.user = r.data;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public async loadCompany() {
        try {
            const r = await this.companyService.summary();
            document.title = r.data.name;
            this.$data.title = document.title;
        } catch (e) {
            document.title = this.$t('title.app').toString();
            this.$data.title = document.title;
        }
    }

    public linkFacebookDialog() {
        const title = this.$t("title.confirmation");
        const message = this.$t("message.link-facebook-confirmation");
        AlertDialogProperties.standard(this.alertDialog, title, message);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "link-facebook" };
        this.alertDialog.visible = true;
    }

    public async linkFacebook() {
        // var hash = this.$route.hash;
        // if (hash && hash.startsWith("#")) {
        //     hash = hash.substring(1);
        // }

        var redirectUrl = RouteUtil.getUrlWithoutPathname(true);
        redirectUrl += "authenticating?type=link&from=profile";
        var r = await this.facebookService.loginUrl(redirectUrl, false);
        window.location.href = r.data;
    }

    public async alertDialogClicked(button: string) {
        var tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag && tag.command === "link-facebook") {
            if (button === this.$t("text.yes")) {
                await this.linkFacebook();
            }
        } 
        await this.properties.events.fire("alert-dialog-clicked", button);
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }
        await this.properties.events.fire("alert-dialog-closed");
    }
    
    public async hamburger() {
        this.$data.drawer = !(this.$data.drawer ?? false);
    }
}
